import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Page } from './Page';

export const TestPage = () => {
  // const product = b2x.useProductById('360909', { populate: b2x.appConfig.api?.productPopulate });

  return (
    <Page>
      <b2x.Image />

      <Container>
        <b2x.Row justifyContent="center">
          <b2x.Col size={10}>
            <div>pre</div>
            <b2x.ImageThron
              aspectRatio={{
                lg: 1163 / 414,
                xs: 794 / 965,
              }}
              fluid
              src={{
                lg: 'https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/d461522e-864a-4904-80ba-dc20df7d3a28/image/Trousse_Glam&Green_Bronze',
                xs: 'https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2',
              }}
            />
            <div>middle</div>
            <picture className="">
              <source
                height="414"
                media="(min-width: 992px)"
                srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/d461522e-864a-4904-80ba-dc20df7d3a28/image/Trousse_Glam&amp;Green_Bronze?crop=centered&amp;w=1163&amp;h=414&amp;dpr=1 1x, https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/d461522e-864a-4904-80ba-dc20df7d3a28/image/Trousse_Glam&amp;Green_Bronze?crop=centered&amp;w=1163&amp;h=414&amp;dpr=2 2x"
                width="1163"
              />
              {/* eslint-disable-next-line jsx-a11y/alt-text, react/forbid-elements */}
              <img
                className="img-fluid"
                height="965"
                src="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2?crop=centered&amp;w=794&amp;h=965&amp;dpr=2"
                srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2?crop=centered&amp;w=794&amp;h=965&amp;dpr=1 1x, https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2?crop=centered&amp;w=794&amp;h=965&amp;dpr=2 2x"
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                width="794"
              />
            </picture>
            <div>post</div>
          </b2x.Col>
        </b2x.Row>
      </Container>
      {/* <picture>
        <source
          height={1700}
          media="(min-width: 1200px)"
          srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2?w=1400&h=1700&crop=centered"
          type="image/jpg"
          width={1400}
        />
        <source
          height={500}
          media="(min-width: 992px)"
          srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2?w=1400&h=500&crop=centered"
          type="image/jpg"
          width={1400}
        />
        <source
          height={1700}
          media="(min-width: 768px)"
          srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2"
          type="image/jpg"
          width={1400}
        />
        <source
          height={1700}
          media="(min-width: 576px)"
          srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2"
          type="image/jpg"
          width={1400}
        />
        <img
          height={1700}
          src="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2"
          srcSet="https://bionike-cdn.thron.com/api/v1/content-delivery/shares/bnjsdz/contents/fbbca31f-f173-4de0-ba38-13d76ff8fc01/image/DC17553_2"
          style={{ height: 'auto', maxWidth: '100%' }}
          width={1400}
        />
      </picture> */}
    </Page>
  );
};
